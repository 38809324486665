import { defineStore } from 'pinia';
import { useMyStore } from './store';
import { useTicketStore } from './ticketStore';
import { usePeriodStore } from './periodStore';
import axios from 'axios'; // Import Axios for making HTTP requests

export const useAuthStore = defineStore('authStore', {
  state: () => ({
    username: '',
    email: '',
    isAuthenticated: false,
    token: null,
  }),
  actions: {
    setToken(token) {
      this.token = token;
      this.isAuthenticated = !!token;  // Sets isAuthenticated based on token presence
      if (!this.isAuthenticated) {
        // Trigger other stores to clear their data when the user logs out
        this.clearOtherStores();
      }
    },
    clearOtherStores() {
      const ticketStore = useTicketStore();
      const myStore = useMyStore();
      const periodStore = usePeriodStore();
  
      ticketStore.clearTickets();
      myStore.clearData();
      periodStore.clearPeriods();
    },
    async checkAuthStatus() {
      const apiUrl = process.env.VUE_APP_API_ENDPOINT;
      try {
        const response = await axios.get(`${apiUrl}/auth/status`, {
            withCredentials: true
        });
        const { token } = response.data;
        this.setToken(token);
        //console.log("Auth status response:", response.data);
      } catch (error) {
        console.error("Error checking authentication status", error);
        this.setToken(null); // This also sets isAuthenticated to false
      }
    },
    login() {
      const apiUrl = process.env.VUE_APP_API_ENDPOINT;
      // Directly navigate to the OAuth login endpoint
      window.location.href = `${apiUrl}/auth/login`;
    },
    logout() {
      const apiUrl = process.env.VUE_APP_API_ENDPOINT;
      axios.get(`${apiUrl}/auth/logout`, { withCredentials: true })
        .then(() => {
          // After successfully logging out, reset the authentication state
          this.setToken(null);  // Reset both token and isAuthenticated
          // Optionally, redirect the user to a public page
          window.location.href = '/';
        })
        .catch(error => {
          console.error("Error during logout", error);
          this.setToken(null);  // Reset both token and isAuthenticated on error as well
        });
    },
  }
});
