<template>
  <div v-if="show" class="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
    <div class="bg-white p-6 rounded-lg shadow-lg max-w-sm mx-auto">
      <h2 class="text-lg font-semibold text-gray-900">Confirm Logout</h2>
      <p class="text-gray-600">Are you sure you want to log out?</p>
      <div class="mt-4 flex justify-center space-x-4">
        <div class="m-1">
          <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" @click="confirm">Confirm</button>
        </div>
        <div class="m-1">
          <button class="bg-gray-300 hover:bg-gray-400 text-black py-2 px-4 rounded" @click="cancel">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean
  },
  emits: ['confirm', 'update:show'],
  methods: {
    confirm() {
      this.$emit('confirm');
      this.$emit('update:show', false);
    },
    cancel() {
      this.$emit('update:show', false);
    }
  }
}
</script>
