<template>
  <div v-if="myStore.isLoading" class="fixed inset-0 bg-gray-100 bg-opacity-75 flex justify-center items-center z-50">
    <div role="status" class="flex flex-col items-center">
      <!-- Brand Spinner -->
      <div class="relative">
        <div class="w-16 h-16 border-4 border-brand-blue rounded-full border-t-transparent animate-spin"></div>
        <div class="absolute inset-0 flex justify-center items-center">
          <div class="w-8 h-8 border-4 border-brand-green rounded-full border-t-transparent animate-spin-reverse"></div>
        </div>
      </div>
      <span class="mt-4 text-lg text-gray-700">Loading...</span>
    </div>
  </div>
</template>

<script>
import { useMyStore } from '../pinia/store'; // Adjust the path as needed

export default {
  setup() {
    const myStore = useMyStore();
    return { myStore };
  }
}
</script>

<style scoped>
@keyframes spin-reverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.animate-spin-reverse {
  animation: spin-reverse 1.5s linear infinite;
}
</style>
