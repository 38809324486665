import { createApp } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import router from './router/index';
import { useAuthStore } from './pinia/authStore'; // Adjust the path to your auth store

const app = createApp(App);
const pinia = createPinia();
app.use(pinia);
app.use(router);

// Perform an initial check of the authentication status
const authStore = useAuthStore();
authStore.checkAuthStatus().then(() => {
  // Mount the app after checking the auth status
  app.mount('#app');
});

// Re-check the authentication status when the window gains focus
window.addEventListener('focus', () => {
  authStore.checkAuthStatus();
});
