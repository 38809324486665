<template>
  <LandingPage />
</template>

<script>
import LandingPage from './components/LandingPage.vue'

export default {
  name: 'App',
  components: {
    LandingPage
  }
}
</script>

<style src="./assets/tailwind.css"/>