<template>
  <div class="relative">
    <div class="data-container max-h-96 overflow-auto bg-white shadow rounded-lg">
      <TicketInformation />
    </div>
    <!-- Button to open modal -->
    <div class="flex justify-center pt-2">
      <button 
        @click="openModal" 
        :disabled="!isAuthenticated" 
        :class="{
          'px-4 py-2 bg-gray-500 text-white rounded': true,
          'hover:bg-cyan-500': isAuthenticated,
          'opacity-50 cursor-not-allowed': !isAuthenticated
        }"
      >
        View Report Preview
      </button>
    </div>

    <!-- Modal -->
    <div v-if="isModalOpen" class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
      <div :class="['bg-white p-6 rounded shadow-lg w-letter overflow-auto', hasRequiredData ? 'h-full' : 'h-auto']">
        <h3 class="text-lg font-bold mb-4 text-center">Report Preview</h3>
        <div v-if="hasRequiredData" id="contentToCapture" ref="contentToCapture" class="bg-white">
          <!-- Modal content -->
          <!-- Modal Header -->
          <div class="px-7 py-3 flex justify-center">
            <div class="border-b border-gray-200">
              <h2 class="text-lg font-semibold text-gray-700">Status Report for: {{ selectedCompanyLabel }}</h2>
            </div>
          </div>

          <!-- Modal Body -->
          <div class="p-7">
            <p class="text-md font-medium text-gray-600 mb-2">{{ selectedFrequencyLabel }} reports are generated for your convenience.</p>
            <p class="text-md font-medium text-gray-600 mb-2">Report for the period of: {{ formattedStartDate }} to {{ formattedEndDate }}</p>
            <!-- Ticket Information Component -->
            <div class="mb-4">
              <TicketInformation />
            </div>
            <!-- Chart Display Component -->
            <ChartDisplay />
          </div>
        </div>
        <div v-else class="flex items-center justify-center">
          <div class="bg-gray-100 p-4 rounded-lg shadow-lg text-center">
            <p class="text-sm font-medium text-gray-600">Please select a company, frequency, and date range to generate a report.</p>
          </div>
        </div>
        <!-- Close button -->
        <div class="flex justify-center pt-2">
          <button @click="closeModal" class="mx-2 mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700">
            Close Preview
          </button>
          <button v-if="hasRequiredData" @click="captureAndDownloadImage" class="mx-2 mt-4 px-4 py-2 bg-cyan-500 text-white rounded hover:bg-cyan-700">Download Report</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import domtoimage from 'dom-to-image';
import { jsPDF } from 'jspdf';
import TicketInformation from './TicketInformation.vue';
import ChartDisplay from './ChartDisplay.vue';
import { useAuthStore } from '../pinia/authStore';
import { useMyStore } from '../pinia/store';
import { usePeriodStore } from '../pinia/periodStore';

export default {
  name: 'ScrollableDataContainer',
  components: {
    TicketInformation,
    ChartDisplay, 
  },
  setup() {
    const notesStore = useMyStore();
    const authStore = useAuthStore();
    return { notesStore, authStore };
  },
  data() {
    return {
      isModalOpen: false,
    };
  },
  computed: {
    myStore() {
      return useMyStore();
    },
    selectedFrequency() {
      return this.myStore.selectedFrequency;
    },
    selectedCompany() {
      return this.myStore.selectedContract;
    },
    startDate() {
      return this.myStore.startDate;
    },
    endDate() {
      return this.myStore.endDate;
    },
    formattedStartDate() {
      return this.myStore.formattedStartDate;
    },
    formattedEndDate() {
      return this.myStore.formattedEndDate;
    },
    selectedFrequencyLabel() {
      return this.myStore.selectedFrequencyLabel;
    },
    selectedCompanyLabel() {
      return this.myStore.selectedContractName;
    },
    isAuthenticated() {
      return this.authStore.isAuthenticated;
    },
    hasRequiredData() {
      return this.selectedCompany && this.selectedFrequency && this.startDate && this.endDate;
    },
  },
  methods: {
    openModal() {
      const periodStore = usePeriodStore();
      this.isModalOpen = true;
      periodStore.setMaxHours(periodStore.maxHours + 10);
    },
    closeModal() {
      this.isModalOpen = false;
    },
    captureAndDownloadImage() {
      const node = this.$refs.contentToCapture;
      
      domtoimage.toPng(node, { 
        quality: 1,
        style: {
          transform: 'scale(2)',
          transformOrigin: 'top left',
        },
        width: node.offsetWidth * 2,
        height: node.offsetHeight * 2
      })
      .then((dataUrl) => {
        this.generatePDF(dataUrl);
      })
      .catch((error) => {
        console.error('Error generating image:', error);
      });
    },
    generatePDF(dataUrl) {
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'letter'
      });

      const imgWidth = 8.5;
      const imgHeight = this.$refs.contentToCapture.offsetHeight * imgWidth / this.$refs.contentToCapture.offsetWidth;
      const filename = this.generateFileName();

      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(dataUrl, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= 11;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(dataUrl, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= 11;
      }

      pdf.save(filename);
    },
    generateFileName() {
      const currentDate = new Date();
      const timestamp = currentDate.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
      const filename = `${timestamp}_${this.selectedCompanyLabel}_${this.formattedStartDate}_to_${this.formattedEndDate}.pdf`;
      return filename.replace(/\s+/g, '_');
    },
  },
};
</script>
