import { defineStore } from 'pinia';
import { useAuthStore } from './authStore';
import { useTicketStore } from './ticketStore';
import { usePeriodStore } from './periodStore';
import axios from 'axios';

export const useMyStore = defineStore({
  id: 'myStore',
  state: () => ({
    contracts: [],
    companies: [],
    issues: [],
    affiliations: [],
    frequencies: [
      { id: 'On-Demand', value: 'On-Demand', label: 'On-Demand' },
      { id: 'Bi-Weekly', value: 'Bi-Weekly', label: 'Bi-weekly' },
      { id: 'Monthly', value: 'Monthly', label: 'Monthly' },
      { id: 'Quarterly', value: 'Quarterly', label: 'Quarterly' },
      { id: 'Yearly', value: 'Yearly', label: 'Yearly' },
      { id: 'Fortnightly', value: 'Fortnightly', label: 'Fortnightly' },
    ],
    selectedContract: '',
    selectedCompany: '',
    selectedFrequency: '',
    selectedFrequencyID: '',
    startDate: null,
    endDate: null,
    UTCStartDate: null,
    UTCEndDate: null,
    reportNotes: '',
    userName: '',
    email: '',
    isLoading: false,
  }),
  actions: {
    convertToEpoch(dateStr) {
      const date = new Date(dateStr);
      return Math.floor(date.getTime() / 1000);
    },
    // Selects a contract and sets the frequency if available
    selectContract(contractId) {
      this.selectedContract = contractId;
      const contract = this.contracts.find(c => c.id === contractId);
      if (contract && contract.frequency) {
        this.selectedFrequency = contract.frequency.id;
      } else {
        this.selectedFrequency = '';
      }
    },
    // Selects a company
    selectCompany(companyId) {
      this.selectedCompany = companyId;
    },
    // Selects a frequency
    selectFrequency(frequencyId) {
      this.selectedFrequency = frequencyId;
    },
    // Updates start date and converts to UTC
    updateStartDate(startDate) {
      this.startDate = startDate;
      const startEpoch = this.convertToEpoch(startDate);
      this.UTCStartDate = startEpoch;
    },
    // Updates end date and converts to UTC
    updateEndDate(endDate) {
      this.endDate = endDate;
      const endEpoch = this.convertToEpoch(endDate);
      this.UTCEndDate = endEpoch;
    },
    setNote(newNote) {
      this.reportNotes = newNote;
    },
    async fetchContracts() {
      const apiUrl = process.env.VUE_APP_API_ENDPOINT;
      const authStore = useAuthStore();
      if (!authStore.token) {
        console.log('Authentication token is not available. Waiting for login.');
        return;
      }

      this.isLoading = true; // Set isLoading to true before making the request

      try {
        const response = await axios.get(`${apiUrl}/accelodsa/contracts`, {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        });

        this.contracts = response.data;
      } catch (error) {
        console.error('Error fetching contracts:', error);
      } finally {
        this.isLoading = false; // Set isLoading to false after the request is complete
      }
    },

    async fetchCompanies() {
      const apiUrl = process.env.VUE_APP_API_ENDPOINT;
      const authStore = useAuthStore();
      if (!authStore.token) {
        throw new Error('Authentication token is not available.');
      }

      try {
        const response = await axios.get(`${apiUrl}/accelogeneral/companies`, {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        });
        this.companies = response.data;
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    },

    async fetchUserData() {
      const apiUrl = process.env.VUE_APP_API_ENDPOINT;
      const authStore = useAuthStore();
      if (!authStore.token) {
        throw new Error('Authentication token is not available.');
      }

      try {
        const response = await axios.get(`${apiUrl}/accelodsa/user`, {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        });

        // Update store state with fetched user data
        this.userName = `${response.data.firstName} ${response.data.surname}`;
        this.email = response.data.email;
        console.log(response);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },

    async fetchIssues(companyId, UTCStartDate, UTCEndDate) {
      const apiUrl = process.env.VUE_APP_API_ENDPOINT;
      const authStore = useAuthStore();
      if (!authStore.token || !companyId || !UTCStartDate || !UTCEndDate) {
        this.error = 'Missing required fields';
        return;
      }
      this.loading = true;
      try {
        const response = await axios.get(`${apiUrl}/accelo/issues`, {
          params: { companyId, start_date: UTCStartDate, end_date: UTCEndDate },
          headers: { Authorization: `Bearer ${authStore.token}` },
        });
        this.issues = response.data.response;
      } catch (error) {
        this.error = error.message || 'Error fetching issues';
      } finally {
        this.loading = false;
      }
    },

    clearData() {
      this.contracts = [];
      this.companies = [];
      this.issues = [];
      this.frequencies = [];
      this.affiliations = [];
      this.startDate = null;
      this.endDate = null;
      this.UTCStartDate = null;
      this.UTCEndDate = null;
      this.reportNotes = '';
      this.userName = null;
      this.email = null;
    },
    changeCompany() {
      const ticketStore = useTicketStore();
      const periodStore = usePeriodStore();

      this.issues = [];
      this.selectedFrequency = [];
      this.affiliations = [];
      this.startDate = null;
      this.endDate = null;
      this.UTCStartDate = null;
      this.UTCEndDate = null;
      this.reportNotes = '';
      ticketStore.clearTickets();
      periodStore.clearPeriods();
    },
  },
  getters: {
    // Getter for the label of the selected frequency
    selectedFrequencyLabel() {
      const frequency = this.frequencies.find((f) => f.id === this.selectedFrequency);
      return frequency ? frequency.label : '';
    },
    // Getter for the name of the selected company
    selectedContractName() {
      const contract = this.contracts.find((f) => f.id === this.selectedContract);
      return contract ? contract.company.name : '';
    },
    selectedCompanyName() {
      const company = this.companies.find((f) => f.id === this.selectedCompany);
      return company ? company.name : '';
    },
    // Getter for formatted start date
    formattedStartDate() {
      if (!this.startDate) return 'Not set';
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(this.startDate).toLocaleDateString('en-US', options);
    },
    // Getter for formatted end date
    formattedEndDate() {
      if (!this.endDate) return 'Not set';
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(this.endDate).toLocaleDateString('en-US', options);
    },
  },
});
