<template>
    <div class="terms-of-service">
      <h1>Terms of Service for Pronto</h1>
      <p><strong>Last Updated:</strong> November 10th 2023</p>
  
      <h2>1. Acceptance of Terms</h2>
      <p>By accessing and using Pronto, you agree to be bound by these Terms of Service. If you do not agree to all of the Terms, do not use this Tool.</p>
  
      <h2>2. Use of the Tool</h2>
      <p>The Tool is provided for the internal business use of PerByte employees only. Use of the Tool for any public or commercial purposes is strictly prohibited without prior written consent from PerByte.</p>
      <p>You agree to use the Tool in compliance with all applicable laws, policies, and regulations, and in a manner that does not negatively impact other users or the performance of the Tool.</p>
      <p>All content, materials, and intellectual property related to the Tool are the exclusive property of PerByte. Unauthorized use or reproduction is prohibited.</p>
      <p>PerByte reserves the right to change or modify these Terms at any time. Continued use of the Tool after such changes indicates your agreement to be bound by the revised Terms.</p>
  
      <h2>Contact Information</h2>
      <p>If you have any questions about these Terms, please contact us at [Contact Information].</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TermsOfService'
  };
  </script>
  
  <style scoped>
  .terms-of-service {
    max-width: 800px;
    margin: auto;
    padding: 20px;
  }
  
  .terms-of-service h1 {
    text-align: center;
  }
  
  .terms-of-service h2 {
    margin-top: 20px;
  }
  </style>
  